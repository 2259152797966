import { extendTheme } from "@chakra-ui/react"

// Version 1: Using objects
const theme = {
    colors: {
        primary: "Teal"
    },
    styles: {
        global: {
            p: {
                paddingBottom: "1rem",
                lineHeight: "1.5rem",
            },
        },
    },
};

export default extendTheme(theme)
